import React from "react";
import "./style.css";
import "./responsive.css";
import Logo from "../assets/images/logo.png";

export default function Header() {
  return (
    <header className="mainheader">
      <div className="header-lower">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid px-0">
              <a className="navbar-brand " href="/">
                <img
                  src={Logo}
                  alt="img"
                  title=""
                  className="headerlogo shadow"
                />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-center"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0 gap-0 gap-lg-3 pt-3 pt-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#aboutus">
                      About us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contactus">
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
