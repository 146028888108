import React from "react";
import "./style.css";
import "./responsive.css";
import Header from "./Header";
import Footer from "./Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <Header />

      <main>
        <div className="page-header">
          <div className="page-header-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1879.8 219"
              enableBackground="new 0 0 1879.8 219"
              xmlSpace="preserve"
            >
              <polygon
                className="shade-path1"
                opacity="0.3"
                fill="#FFFFFF"
                points="0,102.5 1209.9,209.5 1879.8,0 1879.8,219 0,219"
              />
              <polygon
                className="shade-path2"
                fill="#F6F4FE"
                points="0,142.5 1209.9,199.5 1879.8,80 1879.8,219 0,219"
              />
            </svg>

            <div className="container">
              <h2 className="page-header-title " itemProp="headline">
                PRIVACY POLICY
              </h2>
              <nav className="site-breadcrumbs ">
                <ol className="trail-items">
                  <li>
                    <a href="/" rel="home" aria-label="Home">
                      <i className="fa-solid fa-house"></i>
                    </a>
                    <i className="fa-solid fa-chevron-right me-2"></i>
                  </li>
                  <li>
                    <a href="/">
                      <span>PRIVACY POLICY</span>
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pt-3 pt-lg-5  pages">
          <div className="container">
            <h4 className="m-0 mb-lg-1">
              PRIVACY <span>POLICY</span>
            </h4>
            <p className="small text-center mb-3 mb-lg-5">
              Last updated <span>May 13, 2024</span>
            </p>
            <div className="agreement mb-3">
              <h5>AGREEMENT TO OUR PRIVACY POLICY</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <p>
                    This privacy notice for Vera, The Good Rep ({" "}
                    <span className="fw-semibold">"we," "us,"</span> or{" "}
                    <span className="fw-semibold">"our"</span>), describes how
                    and why we might collect, store, use, and/or share (
                    <span className="fw-semibold">"process"</span>) your
                    information when you use our services (
                    <span className="fw-semibold">"Services"</span>), such as
                    when you:
                  </p>
                  <ul className="accordion-list">
                    <li>
                      <p>
                        Visit our website at{" "}
                        <a href="https://veragoodrep.com">
                          https://veragoodrep.com
                        </a>
                        , or any website of ours that links to this privacy
                        notice
                      </p>
                    </li>
                    <li>
                      <p>
                        Download and use our mobile application, or any other
                        application of ours that links to this privacy notice
                      </p>
                    </li>
                    <li>
                      <p>
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="list-group-item">
                  <p>
                    <span className="fw-semibold">Questions or concerns?</span>
                    Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services. If
                    you still have any questions or concerns, please contact us
                    at <a href="/">Info@veragoodrep.com</a>.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="haveanyquestion">
            <div className="container">
              <h5>SUMMARY OF KEY POINTS</h5>
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapseOne"
                      aria-expanded="true"
                      aria-controls="acollapseOne"
                    >
                      What personal information do we process?
                    </button>
                  </h2>
                  <div
                    id="acollapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            When you visit, use, or navigate our Services, we
                            may process personal information depending on how
                            you interact with us and the Services, the choices
                            you make, and the products and features you use.
                            Learn more about{" "}
                            <a href="#collapseOne">
                              {" "}
                              personal information you disclose to us.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapseTwo"
                      aria-expanded="false"
                      aria-controls="acollapseTwo"
                    >
                      Do we process any sensitive personal information?
                    </button>
                  </h2>
                  <div
                    id="acollapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We do not process sensitive personal information.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse3"
                      aria-expanded="false"
                      aria-controls="acollapse3"
                    >
                      Do we receive any information from third parties?
                    </button>
                  </h2>
                  <div
                    id="acollapse3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We do not receive any information from third
                            parties.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse4"
                      aria-expanded="false"
                      aria-controls="acollapse4"
                    >
                      How do we process your information?
                    </button>
                  </h2>
                  <div
                    id="acollapse4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law. We may also process your information for other
                            purposes with your consent. We process your
                            information only when we have a valid legal reason
                            to do so. Learn more about{" "}
                            <a href="#yourinfo">
                              {" "}
                              how we process your information.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse5"
                      aria-expanded="false"
                      aria-controls="acollapse5"
                    >
                      In what situations and with which parties do we share
                      personal information?
                    </button>
                  </h2>
                  <div
                    id="acollapse5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We may share information in specific situations and
                            with specific third parties. Learn more about{" "}
                            <a href="#personalinfo">
                              when and with whom we share your personal
                              information.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse6"
                      aria-expanded="false"
                      aria-controls="acollapse6"
                    >
                      How do we keep your information safe?
                    </button>
                  </h2>
                  <div
                    id="acollapse6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            We have organizational and technical processes and
                            procedures in place to protect your personal
                            information. However, no electronic transmission
                            over the internet or information storage technology
                            can be guaranteed to be 100% secure, so we cannot
                            promise or guarantee that hackers, cybercriminals,
                            or other unauthorized third parties will not be able
                            to defeat our security and improperly collect,
                            access, steal, or modify your information. Learn
                            more about
                            <a href="#infosafe">
                              how we keep your information safe.
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse7"
                      aria-expanded="false"
                      aria-controls="acollapse7"
                    >
                      What are your rights?
                    </button>
                  </h2>
                  <div
                    id="acollapse7"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            Depending on where you are located geographically,
                            the applicable privacy law may mean you have certain
                            rights regarding your personal information. Learn
                            more about
                            <a href="#privacyrights">your privacy rights.</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acollapse8"
                      aria-expanded="false"
                      aria-controls="acollapse8"
                    >
                      How do you exercise your rights?
                    </button>
                  </h2>
                  <div
                    id="acollapse8"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <p>
                            The easiest way to exercise your rights is by
                            submitting a{" "}
                            <a href="https://app.termly.io/notify/bcb8e170-7a4b-4f1f-8cdc-d5b014924020">
                              data subject access request
                            </a>
                            , or by contacting us. We will consider and act upon
                            any request in accordance with applicable data
                            protection laws.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Want to learn more about what we do with any information we
                collect? <a href="/">Review the privacy notice in full.</a>
              </p>
            </div>
          </div>
          <div className="container py-3">
            <h5>Have any questions?</h5>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    WHAT INFORMATION DO WE COLLECT?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse "
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Personal information you disclose to us</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">In Short:</span>We
                          collect personal information that you provide to us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We collect personal information that you voluntarily
                          provide to us when you register on the Services,
                          express an interest in obtaining information about us
                          or our products and Services, when you participate in
                          activities on the Services, or otherwise when you
                          contact us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">
                            Personal Information Provided by You.
                          </span>
                          Personal Information Provided by You. The personal
                          information that we collect depends on the context of
                          your interactions with us and the Services, the
                          choices you make, and the products and features you
                          use. The personal information we collect may include
                          the following:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>names</p>
                          </li>
                          <li>
                            <p>phone numbers</p>
                          </li>
                          <li>
                            <p>email addresses</p>
                          </li>
                          <li>
                            <p>mailing addresses</p>
                          </li>
                          <li>
                            <p>job titles</p>
                          </li>
                          <li>
                            <p>usernames</p>
                          </li>
                          <li>
                            <p>passwords</p>
                          </li>
                          <li>
                            <p>contact or authentication data</p>
                          </li>
                          <li>
                            <p>billing addresses</p>
                          </li>
                          <li>
                            <p>debit/credit card numbers</p>
                          </li>
                          <li>
                            <p>contact preferences</p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">
                            Sensitive Information.
                          </span>
                          We do not process sensitive information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">Payment Data.</span>
                          We may collect data necessary to process your payment
                          if you make purchases, such as your payment instrument
                          number, and the security code associated with your
                          payment instrument. All payment data is stored by
                          Stripe. You may find their privacy notice link(s)
                          here:
                          <a href="https://stripe.com/privacy">
                            https://stripe.com/privacy
                          </a>
                          .
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">Application Data.</span>
                          If you use our application(s), we also may collect the
                          following information if you choose to provide us with
                          access or permission:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              Mobile Device Data. We automatically collect
                              device information (such as your mobile device ID,
                              model, and manufacturer), operating system,
                              version information and system configuration
                              information, device and application identification
                              numbers, browser type and version, hardware model
                              Internet service provider and/or mobile carrier,
                              and Internet Protocol (IP) address (or proxy
                              server). If you are using our application(s), we
                              may also collect information about the phone
                              network associated with your mobile device, your
                              mobile device’s operating system or platform, the
                              type of mobile device you use, your mobile
                              device’s unique device ID, and information about
                              the features of our application(s) you accessed.
                            </p>
                          </li>
                          <li>
                            <p>
                              Push Notifications. We may request to send you
                              push notifications regarding your account or
                              certain features of the application(s). If you
                              wish to opt out from receiving these types of
                              communications, you may turn them off in your
                              device's settings.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          This information is primarily needed to maintain the
                          security and operation of our application(s), for
                          troubleshooting, and for our internal analytics and
                          reporting purposes.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          All personal information that you provide to us must
                          be true, complete, and accurate, and you must notify
                          us of any changes to such personal information.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Information automatically collected</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:s</span>Some
                          information — such as your Internet Protocol (IP)
                          address and/or browser and device characteristics — is
                          collected automatically when you visit our Services.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We automatically collect certain information when you
                          visit, use, or navigate the Services. This information
                          does not reveal your specific identity (like your name
                          or contact information) but may include device and
                          usage information, such as your IP address, browser
                          and device characteristics, operating system, language
                          preferences, referring URLs, device name, country,
                          location, information about how and when you use our
                          Services, and other technical information. This
                          information is primarily needed to maintain the
                          security and operation of our Services, and for our
                          internal analytics and reporting purposes.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Like many businesses, we also collect information
                          through cookies and similar technologies.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>The information we collect includes:</p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Log and Usage Data.
                              </span>{" "}
                              Log and usage data is service-related, diagnostic,
                              usage, and performance information our servers
                              automatically collect when you access or use our
                              Services and which we record in log files.
                              Depending on how you interact with us, this log
                              data may include your IP address, device
                              information, browser type, and settings and
                              information about your activity in the Services
                              (such as the date/time stamps associated with your
                              usage, pages and files viewed, searches, and other
                              actions you take such as which features you use),
                              device event information (such as system activity,
                              error reports (sometimes called "crash dumps"),
                              and hardware settings).
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Device Data.
                              </span>{" "}
                              We collect device data such as information about
                              your computer, phone, tablet, or other device you
                              use to access the Services. Depending on the
                              device used, this device data may include
                              information such as your IP address (or proxy
                              server), device and application identification
                              numbers, location, browser type, hardware model,
                              Internet service provider and/or mobile carrier,
                              operating system, and system configuration
                              information.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fst-italic">
                                Location Data.
                              </span>{" "}
                              We collect location data such as information about
                              your device's location, which can be either
                              precise or imprecise. How much information we
                              collect depends on the type and settings of the
                              device you use to access the Services. For
                              example, we may use GPS and other technologies to
                              collect geolocation data that tells us your
                              current location (based on your IP address). You
                              can opt out of allowing us to collect this
                              information either by refusing access to the
                              information or by disabling your Location setting
                              on your device. However, if you choose to opt out,
                              you may not be able to use certain aspects of the
                              Services.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="yourinfo">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    HOW DO WE PROCESS YOUR INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-semibold">In Short:</span>We
                          process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          We process your personal information for a variety of
                          reasons, depending on how you interact with our
                          Services, including:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To facilitate account creation and
                                authentication and otherwise manage user
                                accounts.
                              </span>{" "}
                              We may process your information so you can create
                              and log in to your account, as well as keep your
                              account in working order.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To deliver and facilitate delivery of services
                                to the user.
                              </span>{" "}
                              We may process your information to provide you
                              with the requested service.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To respond to user inquiries/offer support to
                                users.
                              </span>{" "}
                              We may process your information to respond to your
                              inquiries and solve any potential issues you might
                              have with the requested service.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To fulfill and manage your orders.
                              </span>{" "}
                              We may process your information to fulfill and
                              manage your orders, payments, returns, and
                              exchanges made through the Services.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To enable user-to-user communications.
                              </span>{" "}
                              We may process your information if you choose to
                              use any of our offerings that allow for
                              communication with another user.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To send you marketing and promotional
                                communications.
                              </span>{" "}
                              We may process the personal information you send
                              to us for our marketing purposes, if this is in
                              accordance with your marketing preferences. You
                              can opt out of our marketing emails at any time.
                              For more information, see{" "}
                              <a href="/">"WHAT ARE YOUR PRIVACY RIGHTS?"</a>{" "}
                              below.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To deliver targeted advertising to you.
                              </span>{" "}
                              We may process your information to develop and
                              display personalized content and advertising
                              tailored to your interests, location, and more.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To protect our Services.
                              </span>{" "}
                              We may process your information as part of our
                              efforts to keep our Services safe and secure,
                              including fraud monitoring and prevention.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To evaluate and improve our Services, products,
                                marketing, and your experience.
                              </span>{" "}
                              We may process your information when we believe it
                              is necessary to identify usage trends, determine
                              the effectiveness of our promotional campaigns,
                              and to evaluate and improve our Services,
                              products, marketing, and your experience.
                            </p>
                          </li>
                          <li>
                            <p>
                              {" "}
                              <span className="fw-semibold">
                                To comply with our legal obligations.
                              </span>{" "}
                              We may process your information to comply with our
                              legal obligations, respond to legal requests, and
                              exercise, establish, or defend our legal rights.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="personalinfo">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> We may
                          share information in specific situations described in
                          this section and/or with the following third parties.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may need to share your personal information in the
                          following situations:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              <span className="fw-semibold">
                                Business Transfers.
                              </span>{" "}
                              We may share or transfer your information in
                              connection with, or during negotiations of, any
                              merger, sale of company assets, financing, or
                              acquisition of all or a portion of our business to
                              another company.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fw-semibold">
                                Business Partners.
                              </span>{" "}
                              We may share your information with our business
                              partners to offer you certain products, services,
                              or promotions.
                            </p>
                          </li>
                          <li>
                            <p>
                              <span className="fw-semibold">Other Users.</span>{" "}
                              When you share personal information or otherwise
                              interact with public areas of the Services, such
                              personal information may be viewed by all users
                              and may be publicly made available outside the
                              Services in perpetuity. Similarly, other users
                              will be able to view descriptions of your
                              activity, communicate with you within our
                              Services, and view your profile.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> We may use
                          cookies and other tracking technologies to collect and
                          store your information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may use cookies and similar tracking technologies
                          (like web beacons and pixels) to access or store
                          information. Specific information about how we use
                          such technologies and how you can refuse certain
                          cookies is set out in our Cookie Notice.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> We keep
                          your information for as long as necessary to fulfill
                          the purposes outlined in this privacy notice unless
                          otherwise required by law.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We will only keep your personal information for as
                          long as it is necessary for the purposes set out in
                          this privacy notice, unless a longer retention period
                          is required or permitted by law (such as tax,
                          accounting, or other legal requirements). No purpose
                          in this notice will require us keeping your personal
                          information for longer than the period of time in
                          which users have an account with us.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          When we have no ongoing legitimate business need to
                          process your personal information, we will either
                          delete or anonymize such information, or, if this is
                          not possible (for example, because your personal
                          information has been stored in backup archives), then
                          we will securely store your personal information and
                          isolate it from any further processing until deletion
                          is possible.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="infosafe">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> We aim to
                          protect your personal information through a system of
                          organizational and technical security measures.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We have implemented appropriate and reasonable
                          technical and organizational security measures
                          designed to protect the security of any personal
                          information we process. However, despite our
                          safeguards and efforts to secure your information, no
                          electronic transmission over the Internet or
                          information storage technology can be guaranteed to be
                          100% secure, so we cannot promise or guarantee that
                          hackers, cybercriminals, or other unauthorized third
                          parties will not be able to defeat our security and
                          improperly collect, access, steal, or modify your
                          information. Although we will do our best to protect
                          your personal information, transmission of personal
                          information to and from our Services is at your own
                          risk. You should only access the Services within a
                          secure environment.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> We do not
                          knowingly collect data from or market to children
                          under 18 years of age.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We do not knowingly solicit data from or market to
                          children under 18 years of age. By using the Services,
                          you represent that you are at least 18 or that you are
                          the parent or guardian of such a minor and consent to
                          such minor dependent’s use of the Services. If we
                          learn that personal information from users less than
                          18 years of age has been collected, we will deactivate
                          the account and take reasonable measures to promptly
                          delete such data from our records. If you become aware
                          of any data we may have collected from children under
                          age 18, please contact us at
                          <a href="/">Info@veragoodrep.com</a>.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item" id="privacyrights">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush mb-3">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> You may
                          review, change, or terminate your account at any time.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Withdrawing your consent:
                          </span>{" "}
                          If we are relying on your consent to process your
                          personal information, which may be express and/or
                          implied consent depending on the applicable law, you
                          have the right to withdraw your consent at any time.
                          You can withdraw your consent at any time by
                          contacting us by using the contact details provided in
                          the section{" "}
                          <a href="/">
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                          </a>{" "}
                          below.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          However, please note that this will not affect the
                          lawfulness of the processing before its withdrawal
                          nor, when applicable law allows, will it affect the
                          processing of your personal information conducted in
                          reliance on lawful processing grounds other than
                          consent.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Opting out of marketing and promotional
                            communications:
                          </span>{" "}
                          You can unsubscribe from our marketing and promotional
                          communications at any time by clicking on the
                          unsubscribe link in the emails that we send, replying
                          "STOP" or "UNSUBSCRIBE" to the SMS messages that we
                          send, or by contacting us using the details provided
                          in the section{" "}
                          <a href="/">
                            {" "}
                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                          </a>{" "}
                          below. You will then be removed from the marketing
                          lists. However, we may still communicate with you —
                          for example, to send you service-related messages that
                          are necessary for the administration and use of your
                          account, to respond to service requests, or for other
                          non-marketing purposes.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Account Information</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          If you would at any time like to review or change the
                          information in your account or terminate your account,
                          you can:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Log in to your account settings and update your
                              user account.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Upon your request to terminate your account, we will
                          deactivate or delete your account and information from
                          our active databases. However, we may retain some
                          information in our files to prevent fraud,
                          troubleshoot problems, assist with any investigations,
                          enforce our legal terms and/or comply with applicable
                          legal requirements..
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          <span className="text-decoration-underline fw-semibold">
                            Cookies and similar technologies:
                          </span>{" "}
                          Most Web browsers are set to accept cookies by
                          default. If you prefer, you can usually choose to set
                          your browser to remove cookies and to reject cookies.
                          If you choose to remove cookies or reject cookies,
                          this could affect certain features or services of our
                          Services.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If you have questions or comments about your privacy
                          rights, you may email us at{" "}
                          <a href="/">Info@veragoodrep.com</a>.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          Most web browsers and some mobile operating systems
                          and mobile applications include a Do-Not-Track ("DNT")
                          feature or setting you can activate to signal your
                          privacy preference not to have data about your online
                          browsing activities monitored and collected. At this
                          stage no uniform technology standard for recognizing
                          and implementing DNT signals has been finalized. As
                          such, we do not currently respond to DNT browser
                          signals or any other mechanism that automatically
                          communicates your choice not to be tracked online. If
                          a standard for online tracking is adopted that we must
                          follow in the future, we will inform you about that
                          practice in a revised version of this privacy notice.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          <span className="fw-bold">In Short:</span> If you are
                          a resident of California, Colorado, Connecticut, Utah
                          or Virginia, you are granted specific rights regarding
                          access to your personal information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          What categories of personal information do we collect?
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          We have collected the following categories of personal
                          information in the past twelve (12) months:
                        </p>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th className="w-50">Examples</th>
                              <th className="text-center">Collected</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>A. Identifiers</td>
                              <td>
                                Contact details, such as real name, alias,
                                postal address, telephone or mobile contact
                                number, unique personal identifier, online
                                identifier, Internet Protocol address, email
                                address, and account name
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                B. Personal information as defined in the
                                California Customer Records statute
                              </td>
                              <td>
                                Name, contact information, education,
                                employment, employment history, and financial
                                information
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                C. Protected classNameification characteristics
                                under state or federal law
                              </td>
                              <td>Gender and date of birth</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>D. Commercial information</td>
                              <td>
                                Transaction information, purchase history,
                                financial details, and payment information
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>E. Biometric information</td>
                              <td>Fingerprints and voiceprints</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                F. Internet or other similar network activity
                              </td>
                              <td>
                                Browsing history, search history, online
                                behavior, interest data, and interactions with
                                our and other websites, applications, systems,
                                and advertisements
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>G. Geolocation data</td>
                              <td>Device location</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                H. Audio, electronic, visual, thermal,
                                olfactory, or similar information
                              </td>
                              <td>
                                Images and audio, video or call recordings
                                created in connection with our business
                                activities
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                I. Professional or employment-related
                                information
                              </td>
                              <td>
                                Business contact details in order to provide you
                                our Services at a business level or job title,
                                work history, and professional qualifications if
                                you apply for a job with us
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>J. Education Information</td>
                              <td>Student records and directory information</td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>
                                K. Inferences drawn from collected personal
                                information
                              </td>
                              <td>
                                Inferences drawn from any of the collected
                                personal information listed above to create a
                                profile or summary about, for example, an
                                individual’s preferences and characteristics
                              </td>
                              <td className="text-center">NO</td>
                            </tr>
                            <tr>
                              <td>L. Sensitive personal Information</td>
                              <td></td>
                              <td className="text-center">NO</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may also collect other personal information outside
                          of these categories through instances where you
                          interact with us in person, online, or by phone or
                          mail in the context of:
                        </p>
                        <ul className="accordion-list">
                          <li>
                            <p>
                              Receiving help through our customer support
                              channels;
                            </p>
                          </li>
                          <li>
                            <p>
                              Participation in customer surveys or contests; and
                            </p>
                          </li>
                          <li>
                            <p>
                              Facilitation in the delivery of our Services and
                              to respond to your inquiries.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          How do we use and share your personal information?
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          Learn about how we use your personal information in
                          the section,{" "}
                          <a href="/">"HOW DO WE PROCESS YOUR INFORMATION?"</a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="fw-semibold">
                          Will your information be shared with anyone else?
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may disclose your personal information with our
                          service providers pursuant to a written contract
                          between us and each service provider. Learn more about
                          how we disclose personal information to in the
                          section,
                          <a href="/">
                            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?"
                          </a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          We may use your personal information for our own
                          business purposes, such as for undertaking internal
                          research for technological development and
                          demonstration. This is not considered to be "selling"
                          of your personal information.
                        </p>
                      </li>
                    </ul>

                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>California Residents</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          California Civil Code Section 1798.83, also known as
                          the "Shine The Light" law permits our users who are
                          California residents to request and obtain from us,
                          once a year and free of charge, information about
                          categories of personal information (if any) we
                          disclosed to third parties for direct marketing
                          purposes and the names and addresses of all third
                          parties with which we shared personal information in
                          the immediately preceding calendar year. If you are a
                          California resident and would like to make such a
                          request, please submit your request in writing to us
                          using the contact information provided below.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If you are under 18 years of age, reside in
                          California, and have a registered account with the
                          Services, you have the right to request removal of
                          unwanted data that you publicly post on the Services.
                          To request removal of such data, please contact us
                          using the contact information provided below and
                          include the email address associated with your account
                          and a statement that you reside in California. We will
                          make sure the data is not publicly displayed on the
                          Services, but please be aware that the data may not be
                          completely or comprehensively removed from all our
                          systems (e.g., backups, etc.).
                        </p>
                      </li>
                    </ul>

                    <ul className="list-group list-group-flush mb-3">
                      <p className="text-decoration-underline fw-semibold">
                        CCPA Privacy Notice
                      </p>
                      <li className="list-group-item">
                        <p>
                          This section applies only to California residents.
                          Under the California Consumer Privacy Act (CCPA), you
                          have the rights listed below.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          The California Code of Regulations defines a
                          "residents" as:
                        </p>
                        <ul className="ps-4">
                          <li>
                            <p>
                              (1) every individual who is in the State of
                              California for other than a temporary or
                              transitory purpose and
                            </p>
                          </li>
                          <li>
                            <p>
                              (2) every individual who is domiciled in the State
                              of California who is outside the State of
                              California for a temporary or transitory purpose
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p>
                          All other individuals are defined as "non-residents."
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If this definition of "resident" applies to you, we
                          must adhere to certain rights and obligations
                          regarding your personal information.
                        </p>
                      </li>
                    </ul>

                    <ul className="list-group list-group-flush mb-3">
                      <p className="fw-semibold">
                        Your rights with respect to your personal data
                      </p>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Right to request deletion of the data — Request to
                          delete
                        </p>
                        <p>
                          You can ask for the deletion of your personal
                          information. If you ask us to delete your personal
                          information, we will respect your request and delete
                          your personal information, subject to certain
                          exceptions provided by law, such as (but not limited
                          to) the exercise by another consumer of his or her
                          right to free speech, our compliance requirements
                          resulting from a legal obligation, or any processing
                          that may be required to protect against illegal
                          activities.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Right to be informed — Request to know
                        </p>
                        <p>
                          Depending on the circumstances, you have a right to
                          know:
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              whether we collect and use your personal
                              information;
                            </p>
                          </li>
                          <li>
                            <p>
                              the categories of personal information that we
                              collect;
                            </p>
                          </li>
                          <li>
                            <p>
                              the purposes for which the collected personal
                              information is used;
                            </p>
                          </li>
                          <li>
                            <p>
                              whether we sell or share personal information to
                              third parties;
                            </p>
                          </li>
                          <li>
                            <p>
                              the categories of personal information that we
                              sold, shared, or disclosed for a business purpose;
                            </p>
                          </li>
                          <li>
                            <p>
                              the categories of third parties to whom the
                              personal information was sold, shared, or
                              disclosed for a business purpose;
                            </p>
                          </li>
                          <li>
                            <p>
                              the business or commercial purpose for collecting,
                              selling, or sharing personal information; and
                            </p>
                          </li>
                          <li>
                            <p>
                              the specific pieces of personal information we
                              collected about you.
                            </p>
                          </li>
                        </ul>
                        <p>
                          In accordance with applicable law, we are not
                          obligated to provide or delete consumer information
                          that is de-identified in response to a consumer
                          request or to re-identify individual data to verify a
                          consumer request.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Right to Non-Discrimination for the Exercise of a
                          Consumer’s Privacy Rights
                        </p>
                        <p>
                          We will not discriminate against you if you exercise
                          your privacy rights.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Right to Limit Use and Disclosure of Sensitive
                          Personal Information
                        </p>
                        <p>
                          We do not process consumer's sensitive personal
                          information.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Verification process
                        </p>
                        <p>
                          Upon receiving your request, we will need to verify
                          your identity to determine you are the same person
                          about whom we have the information in our system.
                          These verification efforts require us to ask you to
                          provide information so that we can match it with
                          information you have previously provided us. For
                          instance, depending on the type of request you submit,
                          we may ask you to provide certain information so that
                          we can match the information you provide with the
                          information we already have on file, or we may contact
                          you through a communication method (e.g., phone or
                          email) that you have previously provided to us. We may
                          also use other verification methods as the
                          circumstances dictate.
                        </p>
                        <p>
                          We will only use personal information provided in your
                          request to verify your identity or authority to make
                          the request. To the extent possible, we will avoid
                          requesting additional information from you for the
                          purposes of verification. However, if we cannot verify
                          your identity from the information already maintained
                          by us, we may request that you provide additional
                          information for the purposes of verifying your
                          identity and for security or fraud-prevention
                          purposes. We will delete such additionally provided
                          information as soon as we finish verifying you.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline">
                          Other privacy rights
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              You may object to the processing of your personal
                              information.
                            </p>
                          </li>
                          <li>
                            <p>
                              You may request correction of your personal data
                              if it is incorrect or no longer relevant, or ask
                              to restrict the processing of the information.
                            </p>
                          </li>
                          <li>
                            <p>
                              You can designate an authorized agent to make a
                              request under the CCPA on your behalf. We may deny
                              a request from an authorized agent that does not
                              submit proof that they have been validly
                              authorized to act on your behalf in accordance
                              with the CCPA.
                            </p>
                          </li>
                          <li>
                            <p>
                              You may request to opt out from future selling or
                              sharing of your personal information to third
                              parties. Upon receiving an opt-out request, we
                              will act upon the request as soon as feasibly
                              possible, but no later than fifteen (15) days from
                              the date of the request submission.
                            </p>
                          </li>
                        </ul>
                        <p>
                          To exercise these rights, you can contact us by
                          submitting a{" "}
                          <a href="/">data subject access request</a>, by email
                          at <a href="/">Info@veragoodrep.com</a>, or by
                          referring to the contact details at the bottom of this
                          document. If you have a complaint about how we handle
                          your data, we would like to hear from you.
                        </p>
                      </li>
                    </ul>

                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Colorado Residents</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          This section applies only to Colorado residents. Under
                          the Colorado Privacy Act (CPA), you have the rights
                          listed below. However, these rights are not absolute,
                          and in certain cases, we may decline your request as
                          permitted by law.
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Right to be informed whether or not we are
                              processing your personal data
                            </p>
                          </li>
                          <li>
                            <p>Right to access your personal data</p>
                          </li>
                          <li>
                            <p>
                              Right to correct inaccuracies in your personal
                              data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to request deletion of your personal data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to obtain a copy of the personal data you
                              previously shared with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to opt out of the processing of your
                              personal data if it is used for targeted
                              advertising, the sale of personal data, or
                              profiling in furtherance of decisions that produce
                              legal or similarly significant effects
                              ("profiling")
                            </p>
                          </li>
                        </ul>
                        <p>
                          To submit a request to exercise these rights described
                          above, please email Info@veragoodrep.com or submit a{" "}
                          <a href="/">data subject access request.</a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If we decline to take action regarding your request
                          and you wish to appeal our decision, please email us
                          at Info@veragoodrep.com. Within forty-five (45) days
                          of receipt of an appeal, we will inform you in writing
                          of any action taken or not taken in response to the
                          appeal, including a written explanation of the reasons
                          for the decisions.
                        </p>
                      </li>
                    </ul>

                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Connecticut Residents</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          This section applies only to Connecticut residents.
                          Under the Connecticut Data Privacy Act (CTDPA), you
                          have the rights listed below. However, these rights
                          are not absolute, and in certain cases, we may decline
                          your request as permitted by law.
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Right to be informed whether or not we are
                              processing your personal data
                            </p>
                          </li>
                          <li>
                            <p>Right to access your personal data</p>
                          </li>
                          <li>
                            <p>
                              Right to correct inaccuracies in your personal
                              data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to request deletion of your personal data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to obtain a copy of the personal data you
                              previously shared with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to opt out of the processing of your
                              personal data if it is used for targeted
                              advertising, the sale of personal data, or
                              profiling in furtherance of decisions that produce
                              legal or similarly significant effects
                              ("profiling")
                            </p>
                          </li>
                        </ul>
                        <p>
                          To submit a request to exercise these rights described
                          above, please email Info@veragoodrep.com or submit a{" "}
                          <a href="/">data subject access request.</a>
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p>
                          If we decline to take action regarding your request
                          and you wish to appeal our decision, please email us
                          at Info@veragoodrep.com. Within forty-five (60) days
                          of receipt of an appeal, we will inform you in writing
                          of any action taken or not taken in response to the
                          appeal, including a written explanation of the reasons
                          for the decisions.
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Utah Residents</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          This section applies only to Utah residents. Under the
                          Utah Consumer Privacy Act (UCPA), you have the rights
                          listed below. However, these rights are not absolute,
                          and in certain cases, we may decline your request as
                          permitted by law.
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Right to be informed whether or not we are
                              processing your personal data
                            </p>
                          </li>
                          <li>
                            <p>Right to access your personal data</p>
                          </li>
                          <li>
                            <p>
                              Right to request deletion of your personal data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to obtain a copy of the personal data you
                              previously shared with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to opt out of the processing of your
                              personal data if it is used for targeted
                              advertising, the sale of personal data
                            </p>
                          </li>
                        </ul>
                        <p>
                          To submit a request to exercise these rights described
                          above, please email Info@veragoodrep.com or submit a{" "}
                          <a href="/">data subject access request.</a>
                        </p>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mb-3">
                      <h6>
                        <strong>Virginia Residents</strong>
                      </h6>
                      <li className="list-group-item">
                        <p>
                          Under the Virginia Consumer Data Protection Act
                          (VCDPA):
                        </p>
                        <p>
                          "Consumer" means a natural person who is a resident of
                          the Commonwealth acting only in an individual or
                          household context. It does not include a natural
                          person acting in a commercial or employment context.
                        </p>
                        <p>
                          "Personal data" means any information that is linked
                          or reasonably linkable to an identified or
                          identifiable natural person. "Personal data" does not
                          include de-identified data or publicly available
                          information.
                        </p>
                        <p>
                          "Sale of personal data" means the exchange of personal
                          data for monetary consideration.
                        </p>
                        <p>
                          If this definition of "consumer" applies to you, we
                          must adhere to certain rights and obligations
                          regarding your personal data.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline ">
                          Your rights with respect to your personal data
                        </p>
                        <ul className="accordion-list mb-3">
                          <li>
                            <p>
                              Right to be informed whether or not we are
                              processing your personal data
                            </p>
                          </li>
                          <li>
                            <p>Right to access your personal data</p>
                          </li>
                          <li>
                            <p>
                              Right to correct inaccuracies in your personal
                              data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to request deletion of your personal data
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to obtain a copy of the personal data you
                              previously shared with us
                            </p>
                          </li>
                          <li>
                            <p>
                              Right to opt out of the processing of your
                              personal data if it is used for targeted
                              advertising, the sale of personal data, or
                              profiling in furtherance of decisions that produce
                              legal or similarly significant effects
                              ("profiling")
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline ">
                          Exercise your rights provided under the Virginia VCDPA
                        </p>
                        <p>
                          To submit a request to exercise these rights described
                          above, please email Info@veragoodrep.com or submit a{" "}
                          <a href="/">data subject access request.</a>
                        </p>
                        <p>
                          If you are using an authorized agent to exercise your
                          rights, we may deny a request if the authorized agent
                          does not submit proof that they have been validly
                          authorized to act on your behalf.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline ">
                          Verification process
                        </p>
                        <p>
                          We may request that you provide additional information
                          reasonably necessary to verify you and your consumer's
                          request. If you submit the request through an
                          authorized agent, we may need to collect additional
                          information to verify your identity before processing
                          your request.
                        </p>
                        <p>
                          Upon receiving your request, we will respond without
                          undue delay, but in all cases, within forty-five (45)
                          days of receipt. The response period may be extended
                          once by forty-five (45) additional days when
                          reasonably necessary. We will inform you of any such
                          extension within the initial 45-day response period,
                          together with the reason for the extension.
                        </p>
                      </li>
                      <li className="list-group-item">
                        <p className="text-decoration-underline ">
                          Right to appeal
                        </p>
                        <p>
                          If we decline to take action regarding your request,
                          we will inform you of our decision and reasoning
                          behind it. If you wish to appeal our decision, please
                          email us at <a href="/"> Info@veragoodrep.com.</a>
                          Within sixty (60) days of receipt of an appeal, we
                          will inform you in writing of any action taken or not
                          taken in response to the appeal, including a written
                          explanation of the reasons for the decisions. If your
                          appeal is denied, you may contact the{" "}
                          <a href="/">
                            {" "}
                            Attorney General to submit a complaint.
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapse11"
                  >
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </button>
                </h2>
                <div
                  id="collapse11"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush mb-3">
                        <li className="list-group-item">
                          <p>
                            <span className="fw-semibold">In Short:</span>
                            Yes, we will update this notice as necessary to stay
                            compliant with relevant laws.
                          </p>
                        </li>
                        <li className="list-group-item">
                          <p>
                            We may update this privacy notice from time to time.
                            The updated version will be indicated by an updated
                            "Revised" date and the updated version will be
                            effective as soon as it is accessible. If we make
                            material changes to this privacy notice, we may
                            notify you either by prominently posting a notice of
                            such changes or by directly sending you a
                            notification. We encourage you to review this
                            privacy notice frequently to be informed of how we
                            are protecting your information.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapse12"
                  >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </button>
                </h2>
                <div
                  id="collapse12"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <p>
                        If you have questions or comments about this notice, you
                        may email us at Info@veragoodrep.com or contact us by
                        post at:
                      </p>
                      <li className="list-group-item">
                        <address>Vera, The Good Rep</address>
                        <address>165 Cambridgepark Dr</address>
                        <address>Cambridge, MA 02140</address>
                        <address>United States</address>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapse13"
                  >
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </button>
                </h2>
                <div
                  id="collapse13"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <p>
                          You have the right to request access to the personal
                          information we collect from you, change that
                          information, or delete it. To request to review,
                          update, or delete your personal information, please
                          fill out and submit a{" "}
                          <a href="/"> data subject access request.</a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
