import React from "react";
import "./style.css";
import "./responsive.css";
import Logo from "../assets/images/logo.png";

export default function Footer() {
  return (
    <footer>
      {/* <div className="container pt-5">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <a href="/">
              <img src={Logo} alt="" className="footerlogo" />{" "}
            </a>
            <p className="footer-logo ">
              Vera was developed by a team that was all too aware of the
              negative impacts of the “rogue” salesperson; misinformation can
              ruin an industry.
            </p>
          </div>

          <div className="col-6 col-sm-3 col-lg-2 mb-3">
            <h5>Legal</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="/termscondition"
                  className="nav-link p-0 text-body-secondary"
                >
                  Terms & conditions
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/privacypolicy"
                  className="nav-link p-0 text-body-secondary"
                >
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center py-4 my-4 border-top flex-wrap">
          <p className="mb-0 text-center">
            © 2024 Company, Inc. All rights reserved.
          </p>
        </div>
      </div> */}
      {/* Updated footer code  */}
      <div className="container pt-5">
        <div className="row mb-5">
          <div className="col-md-6 col-lg-7 mb-3">
            <a href="/">
              <img src={Logo} alt="logo-icon" className="footerlogo" />
            </a>
            <p className="footer-logo ">
              Vera was developed by a team that was all too aware of the
              negative impacts of the “rogue” salesperson; misinformation can
              ruin an industry. We felt strongly that solar is an industry that
              can positively impact the world, so we decided to make a change.
            </p>
            <h6>
              Email:
              <a href="mailto:info@veragoodrep.com">info@veragoodrep.com</a>
            </h6>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2 mb-3 offset-lg-1">
            <h5>OUR LINKS</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-body-secondary">
                  Home
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/#aboutus"
                  className="nav-link p-0 text-body-secondary"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/#contactus"
                  className="nav-link p-0 text-body-secondary"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
            <h5>USEFUL LINKS</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="/termscondition"
                  className="nav-link p-0 text-body-secondary"
                >
                  Terms & Condition
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="/privacypolicy"
                  className="nav-link p-0 text-body-secondary"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center py-4 mt-4 border-top">
          <p className="mb-0">
            Copyright © 2024 Design by <span>Vera</span>
          </p>
        </div>
      </div>
    </footer>
  );
}
